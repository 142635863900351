import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import styled from 'styled-components'

import {Button, Stack, Text} from '@pleo-io/telescope'

import {PlanTypeName} from '@product-web/api-types/billing'
import type {CpqRatePlanType} from '@product-web/bff-moons/generated/beyond'

import DowngradeConfirmationSVG from './done-step-confirmation-downgrade.svg'
import UpgradeConfirmationSVG from './done-step-confirmation-upgrade.svg'

import type {PageOrigin, PageSection} from '../../types'
import {trackPricingPlansModalActioned} from '../pricing-plans.helper'

type DoneStepProps = {
    onClose: () => void
    isUpgrade: boolean
    newPlan: CpqRatePlanType | null
    pageOrigin: PageOrigin
    pageSection?: PageSection
}

const StepWrapper = styled(Stack)`
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    height: 100%;
`

export const DoneStep = ({onClose, isUpgrade, newPlan, pageOrigin, pageSection}: DoneStepProps) => {
    const planName = PlanTypeName[newPlan!]

    useEffect(() => {
        trackPricingPlansModalActioned({
            action: 'viewed',
            step: 'completed_plan_changes',
            origin: pageOrigin,
            section: pageSection,
            plan: newPlan!,
        })
    }, [])

    const handleOnClose = () => {
        trackPricingPlansModalActioned({
            action: 'done',
            step: 'completed_plan_changes',
            origin: pageOrigin,
            section: pageSection,
            plan: newPlan!,
        })

        onClose()
    }

    return (
        <StepWrapper align="center" space={24}>
            <img
                src={isUpgrade ? UpgradeConfirmationSVG : DowngradeConfirmationSVG}
                alt=""
                width={320}
                height={240}
                css={{margin: '0 auto'}}
            />
            <Text variant="2xlarge-accent" weight="medium">
                <Trans>Welcome to {planName} plan</Trans>
            </Text>
            <Text color="colorContentStaticQuiet">
                {isUpgrade
                    ? t`Your account has been updated and all new features have been unlocked. We've sent an email confirming the details of your new plan. `
                    : t`Your account has been updated together with any feature changes. We've also sent you an email confirming the details of your new plan.`}
            </Text>
            <Button variant="primary" onClick={handleOnClose}>
                <Trans>Continue</Trans>
            </Button>
        </StepWrapper>
    )
}
