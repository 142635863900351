import {t} from '@lingui/macro'
import {useEffect} from 'react'

import {ModalSplit, useModalSplitSteps} from '@pleo-io/telescope'

import {type AnnouncementSteps, startSpendActivationSetup} from '../tracking.helpers'

interface RecurringSpendAnnouncementProps {
    isOpen: boolean
    setShowModal: (show: boolean) => void
}
export const RecurringSpendAnnouncement = ({
    isOpen,
    setShowModal,
}: RecurringSpendAnnouncementProps) => {
    const steps = [
        {stepId: 'manage-spend'},
        {stepId: 'money-aside'},
        {stepId: 'vendor-cards'},
        {stepId: 'setup'},
    ]
    const {activeStep, setActiveStep, nextStep, previousStep} = useModalSplitSteps({
        steps,
        initialActiveStep: 'manage-spend',
    })

    const handleClose = () => {
        setShowModal(false)
        setActiveStep('manage-spend')
    }

    useEffect(() => {
        startSpendActivationSetup({step: activeStep as AnnouncementSteps})
    }, [activeStep])

    const pinkSlides = ['manage-spend', 'setup']
    const getIllustrationVariant = pinkSlides.includes(activeStep) ? 'pink' : 'gray'

    const announcementStepsContent = [
        {
            stepId: 'manage-spend',
            title: t`A better way to manage your business critical spend`,
            text: t`Activate our smart features to gain full visibility and tighter control over your marketing or IT budgets.`,
            onClickPrev: undefined,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            illustration: undefined,
        },
        {
            stepId: 'money-aside',
            title: t`Set money aside with dedicated sub-wallets`,
            text: t`Keep your budget organised and separate by having enough funds to spend without interruptions and avoiding the risk of overspending.`,
            onClickPrev: previousStep,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            illustration: undefined,
        },
        {
            stepId: 'vendor-cards',
            title: t`Use vendor cards to keep business expenses in check`,
            text: t`Assign cards to your go-to suppliers or services, switch card ownership with just a click, and let automated data entry take care of bookkeeping for you.`,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            onClickPrev: previousStep,
            illustration: undefined,
        },
        {
            stepId: 'setup',
            title: t`Follow our easy steps and master your spend management`,
            text: undefined,
            onClickPrev: previousStep,
            onClickNext: () => {
                handleClose()
                startSpendActivationSetup({call_to_action: 'start_setup'})
            },
            nextButtonLabel: t`Set up now`,
            illustration: undefined,
        },
    ]

    return (
        <ModalSplit isOpen={isOpen} onDismiss={handleClose}>
            <ModalSplit.Content>
                <ModalSplit.StepIndicator
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={setActiveStep}
                />
                <ModalSplit.Steps activeStepId={activeStep}>
                    {announcementStepsContent.map((step) => {
                        return (
                            <ModalSplit.Step stepId={step.stepId} key={step.stepId}>
                                <ModalSplit.Body>
                                    {/* TO BE enabled later */}
                                    {/* {activeStep === 'manage-spend' && (
                                        <PlanTag variant="green">
                                            <Trans>Included in your plan</Trans>
                                        </PlanTag>
                                    )} */}
                                    <ModalSplit.Title>{step.title}</ModalSplit.Title>
                                    <ModalSplit.Text>{step.text}</ModalSplit.Text>
                                </ModalSplit.Body>
                                <ModalSplit.Actions>
                                    {step.onClickPrev && (
                                        <ModalSplit.BackButton onClick={step.onClickPrev} />
                                    )}
                                    {step.onClickNext && (
                                        <ModalSplit.NextButton onClick={step.onClickNext}>
                                            {step.nextButtonLabel}
                                        </ModalSplit.NextButton>
                                    )}
                                </ModalSplit.Actions>
                            </ModalSplit.Step>
                        )
                    })}
                </ModalSplit.Steps>

                {/* TO BE enabled later */}
                {/* <ModalSplit.Footer>
                    <Trans>Want to do it later?</Trans>{' '}
                    <Link href="">
                        <Trans>Send email reminder</Trans>
                    </Link>
                </ModalSplit.Footer> */}
            </ModalSplit.Content>
            <ModalSplit.IllustrationContainer variant={getIllustrationVariant}>
                <ModalSplit.Steps activeStepId={activeStep}>
                    {announcementStepsContent.map((step) => {
                        return (
                            <ModalSplit.Step
                                stepId={step.stepId}
                                key={'illustration-' + step.stepId}
                            >
                                <ModalSplit.Illustration>
                                    <img src={step.illustration} alt="" />
                                    {/* <img src={reimbursements} alt="" /> */}
                                </ModalSplit.Illustration>
                            </ModalSplit.Step>
                        )
                    })}
                </ModalSplit.Steps>
            </ModalSplit.IllustrationContainer>
        </ModalSplit>
    )
}

// const PlanTag = styled(Tag)`
//     margin-top: ${tokens.spacing8};
//     margin-bottom: ${tokens.spacing8};
// `
