import {Trans} from '@lingui/macro'
import {styled} from 'styled-components'

import {Box, Callout, Text} from '@pleo-io/telescope'
import {Info} from '@pleo-io/telescope-icons'

import type {Amount} from '@product-web/bff-moons/generated/beyond'
import type {MinorCurrency} from '@product-web/locale/currency'
import {formatMinorCurrency} from '@product-web/locale/currency'

interface BookkeeperInfoBannerProps {
    trialEndDate?: string
    pricePerBookkeeper?: Amount
    currentPlan?: string
    isInTrialPeriod: boolean
    preSelectedPlatformPlan: string
}

export const BookkeeperInfoBanner = ({
    trialEndDate,
    pricePerBookkeeper,
    currentPlan,
    isInTrialPeriod,
    preSelectedPlatformPlan,
}: BookkeeperInfoBannerProps) => {
    let price
    if (pricePerBookkeeper) {
        price = formatMinorCurrency({
            value: pricePerBookkeeper.value as MinorCurrency,
            currency: pricePerBookkeeper.currency,
        })
    }

    if (
        !isInTrialPeriod &&
        (currentPlan === 'STARTER' || preSelectedPlatformPlan.toLowerCase() === 'starter')
    ) {
        return (
            <CalloutWrapper>
                <Callout variant="neutral" Icon={Info}>
                    <Text>
                        <Trans>
                            Your company is on a Starter plan, which means you'll pay{' '}
                            <b>{price}/month</b> per bookkeeper. Bookkeeper access is included in
                            the <b>Essential</b> plan.
                        </Trans>
                    </Text>
                </Callout>
            </CalloutWrapper>
        )
    }

    if (isInTrialPeriod && preSelectedPlatformPlan.toLowerCase() === 'essential') {
        return (
            <CalloutWrapper>
                <Callout variant="discover" Icon={Info}>
                    <Text>
                        <Trans>
                            Bookkeeper access is included in the <b>Essential</b> plan. If you're on
                            Starter, you'll pay <b>{price}/month</b> per bookkeeper from{' '}
                            {trialEndDate}.
                        </Trans>
                    </Text>
                </Callout>
            </CalloutWrapper>
        )
    }

    if (isInTrialPeriod && preSelectedPlatformPlan.toLowerCase() === 'starter') {
        return (
            <CalloutWrapper>
                <Callout variant="discover" Icon={Info}>
                    <Text>
                        <Trans>
                            Bookkeeper access is included in the <b>Essential</b> plan. If you
                            downgrade to Starter, you'll pay <b>{price}/month</b> per bookkeeper.
                        </Trans>
                    </Text>
                </Callout>
            </CalloutWrapper>
        )
    }

    return null
}

const CalloutWrapper = styled(Box).attrs({mb: 24, mx: 'auto'})`
    max-width: 655px;
`
