import {t} from '@lingui/macro'

import {choosePlanModalActioned, downgradeBlockedModalActioned} from '@product-web/analytics'
import {PlanTypeName} from '@product-web/api-types/billing'
import type {
    BillingInfoCurrency,
    CpqBillingPeriodType,
    CpqRatePlanType,
} from '@product-web/bff-moons/generated/beyond'
import type {SupportedLanguage} from '@product-web/i18n'
import {exhaustiveCheck} from '@product-web/utils'

import {formatMinorNumberToCurrency} from '../lib/currency-formatter'
import type {PageOrigin, PageSection} from '../types'

type PlanConfiguration = {
    planName: string
    description: string
    subTitle: string
    entitlements: string[]
    allEntitlements: string[]
    contactSalesEnabled: boolean
    cashback?: number
    overdraft?: {[currency: string]: number}
    tempCards?: {[currency: string]: number}
    reimbursementsPercentage?: number
    reimbursementsCap?: {[currency: string]: number}
}

// This has to be in a fuction because of the t macro
const getStarterEntitlements = () => [t`Real-time expense tracking`, t`Automated expense reports`]
const getEssentialEntitlements = () => [
    t`Reimbursements`,
    t`Mileage & per diem`,
    t`Flexible card limits`,
    t`Automated review flows`,
]
const getAdvancedEntitlements = () => [
    t`Multi-entity management`,
    t`Vendor management`,
    t`Advanced controls`,
    t`Advanced roles & permissions`,
    t`HRIS integrations`,
]
const getBeyondEntitlements = () => [
    t`Spending insights`,
    t`Subwallets`,
    t`Phone support`,
    t`Concierge support`,
    t`Customer success manager`,
]

export const getPlanContentConfiguration = (planType: CpqRatePlanType): PlanConfiguration => {
    switch (planType) {
        case 'STARTER':
            return {
                planName: PlanTypeName.STARTER,
                description: t`Start automating expense management and bookkeeping.`,
                subTitle: t`Includes`,
                entitlements: getStarterEntitlements(),
                allEntitlements: getStarterEntitlements(),
                contactSalesEnabled: false,
                cashback: undefined,
                overdraft: undefined,
                tempCards: undefined,
                reimbursementsPercentage: undefined,
                reimbursementsCap: undefined,
            }
        case 'ESSENTIAL':
            return {
                planName: PlanTypeName.ESSENTIAL,
                description: t`Streamline all expenses and accounts payable through a single platform.`,
                subTitle: t`Everything in Starter, plus`,
                entitlements: getEssentialEntitlements(),
                allEntitlements: [...getStarterEntitlements(), ...getEssentialEntitlements()],
                contactSalesEnabled: false,
                cashback: undefined,
                overdraft: {
                    EUR: 2000000,
                    GBP: 2000000,
                    USD: 0,
                    DKK: 14800000,
                    NOK: 0,
                    SEK: 20000000,
                },
                tempCards: undefined,
                reimbursementsPercentage: 0.9,
                reimbursementsCap: {
                    EUR: 100,
                    GBP: 100,
                    USD: 100,
                    DKK: 1000,
                    NOK: 1000,
                    SEK: 1000,
                },
            }
        case 'ADVANCED':
            return {
                planName: PlanTypeName.ADVANCED,
                description: t`Scale spend control and efficiency across systems and entities.`,
                subTitle: t`Everything in Essential, plus`,
                entitlements: getAdvancedEntitlements(),
                allEntitlements: [
                    ...getStarterEntitlements(),
                    ...getEssentialEntitlements(),
                    ...getAdvancedEntitlements(),
                ],
                contactSalesEnabled: true,
                cashback: 0.5,
                overdraft: {
                    EUR: 25000000,
                    GBP: 25000000,
                    USD: 0,
                    DKK: 190000000,
                    NOK: 0,
                    SEK: 250000000,
                },
                tempCards: {
                    EUR: 90,
                    GBP: 90,
                    USD: 90,
                    DKK: 700,
                    NOK: 1000,
                    SEK: 1000,
                },
                reimbursementsPercentage: 0.9,
                reimbursementsCap: {
                    EUR: 100,
                    GBP: 100,
                    USD: 100,
                    DKK: 1000,
                    NOK: 1000,
                    SEK: 1000,
                },
            }
        case 'BEYOND':
            return {
                planName: PlanTypeName.BEYOND,
                description: t`Master business spend with smart insights and premium support.`,
                subTitle: t`Everything in Advanced, plus`,
                entitlements: getBeyondEntitlements(),
                allEntitlements: [
                    ...getStarterEntitlements(),
                    ...getEssentialEntitlements(),
                    ...getAdvancedEntitlements(),
                    ...getBeyondEntitlements(),
                ],
                contactSalesEnabled: true,
                cashback: 0.75,
                overdraft: {
                    EUR: 50000000,
                    GBP: 50000000,
                    USD: 0,
                    DKK: 380000000,
                    NOK: 0,
                    SEK: 500000000,
                },
                tempCards: {
                    EUR: 90,
                    GBP: 90,
                    USD: 90,
                    DKK: 700,
                    NOK: 1000,
                    SEK: 1000,
                },
                reimbursementsPercentage: 0,
                reimbursementsCap: undefined,
            }
        default:
            return exhaustiveCheck(planType)
    }
}

const renderUpToCreditPrice = (priceText: string) => t`Up to ${priceText} credit`

export const renderUpToCashbackLine = (percentage: number) =>
    percentage ? t`Up to ${percentage}% cashback` : ''

export const renderUpToCreditLine = ({
    planConfiguration,
    currency,
    locale,
}: {
    planConfiguration: PlanConfiguration
    currency: BillingInfoCurrency
    locale: SupportedLanguage
}) => {
    const value = planConfiguration.overdraft?.[currency]

    return value
        ? renderUpToCreditPrice(
              formatMinorNumberToCurrency({
                  value,
                  options: {
                      currency,
                      maximumFractionDigits: 1,
                      notation: 'compact',
                  },
                  locale,
              }),
          )
        : ''
}

export const getArrayDifference = (array1: string[], array2: string[]) => {
    const difference = []
    const longestArray = array1.length > array2.length ? array1 : array2
    const lookupSet = new Set(array1.length < array2.length ? array1 : array2)

    for (let i = 0; i < longestArray.length; i++) {
        if (!lookupSet.has(longestArray[i])) {
            difference.push(longestArray[i])
        }
    }

    return difference
}

export const isOverdraftAvailable = (currency: string): boolean => {
    return (['STARTER', 'ESSENTIAL', 'ADVANCED', 'BEYOND'] as CpqRatePlanType[]).some((plan) => {
        const {overdraft} = getPlanContentConfiguration(plan)

        return overdraft && overdraft?.[currency] > 0
    })
}

// Keeping here only the types of the values used in the new modal
// so we can easily remove the deprecated ones post release
export const trackPricingPlansModalActioned = ({
    action,
    step,
    origin,
    section,
    plan,
    previousPlan,
    interval,
}: {
    action:
        | 'viewed'
        | 'abandoned'
        | 'back'
        | 'get_started'
        | 'completed'
        | 'done'
        | 'annual_toggle_clicked'
        | 'contact_us_clicked'
        | 'view_all_link'
        | 'master_service_agreement_link'
        | 'data_processing_agreement_link'
        | 'privacy_policy_link'
    step: 'payment_plan' | 'pricing_plan' | 'completed_plan_changes'
    origin: PageOrigin
    section?: PageSection
    plan?: CpqRatePlanType
    previousPlan?: CpqRatePlanType
    interval?: CpqBillingPeriodType
}) => {
    choosePlanModalActioned({
        action,
        step,
        origin,
        ...(section ? {section} : null),
        ...(plan ? {plan} : null),
        ...(previousPlan ? {previousPlan} : null),
        ...(interval ? {interval} : null),
    })
}

export const trackDowngradeBlockedModalActioned = ({
    variant,
    action,
    origin,
    section,
}: {
    variant: 'user_limit' | 'annual' | 'termed'
    action: 'viewed' | 'start_chat' | 'manage_users' | 'abandoned'
    origin: PageOrigin
    section?: PageSection
}) => {
    downgradeBlockedModalActioned({
        variant,
        action,
        origin,
        ...(section ? {section} : null),
    })
}
