import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'

import {Button, Card, Inline, Link, Skeleton, Stack, Text, tokens} from '@pleo-io/telescope'

import {PlanTypeName} from '@product-web/api-types/billing'
import type {PlanUpgradeSource} from '@product-web/api-types/plan'
import type {
    Amount,
    CpqBillingPeriodType,
    CpqRatePlanType,
} from '@product-web/bff-moons/generated/beyond'
import {useTermsDocuments} from '@product-web/country-configuration/features/terms/terms'
import {reportError} from '@product-web/error/report'
import {breakpoints} from '@product-web/styles/theme'
import {getCountry, useUser} from '@product-web/user'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {ContactSupport} from '@product-web-features/ui-contact-support'

import {trackPricingPlansModalActioned} from './pricing-plans.helper'

import {bff} from '../bff-hooks'
import {formatMinorNumberToCurrency} from '../lib/currency-formatter'
import type {PageOrigin, PageSection} from '../types'

type PaymentConfirmationBreakdownProps = {
    targetBillingType: CpqBillingPeriodType
    targetPlan: {planType: CpqRatePlanType; isUpgrade: boolean}
    onConfirmation: () => void
    currentPlan: CpqRatePlanType
    currentPlanEstimatedTotal: Amount | null
    currentBillingType: CpqBillingPeriodType
    onError: () => void
    planUpgradeSource: PlanUpgradeSource
    pageOrigin: PageOrigin
    pageSection?: PageSection
}

const paymentFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}

export const PaymentConfirmationBreakdown = ({
    targetBillingType,
    onConfirmation,
    onError,
    targetPlan,
    currentPlan,
    currentPlanEstimatedTotal,
    currentBillingType,
    planUpgradeSource,
    pageOrigin,
    pageSection,
}: PaymentConfirmationBreakdownProps) => {
    const user = useUser()

    const {
        data,
        isFetching,
        error: isPaymentSummaryError,
    } = bff.paywall.planFeatures.getPaymentSummary.useQuery({
        ratePlanType: targetPlan.planType,
        additional_users_quantity: user?.company?.numberOfUsers ?? 0,
    })

    const trackingStep = 'payment_plan'

    const {mutate: updateCompanySubscription, isLoading: isUpdateCompanySubscriptionLoading} =
        bff.paywall.planFeatures.updateCompanySubscription.useMutation({
            onSuccess: () => {
                trackPricingPlansModalActioned({
                    action: 'completed',
                    step: trackingStep,
                    origin: pageOrigin,
                    section: pageSection,
                    plan: targetPlan.planType,
                    previousPlan: currentPlan,
                    interval: targetBillingType,
                })

                onConfirmation()
            },
            onError: (error) => {
                reportError(error)
                onError()
            },
        })

    const country = getCountry(user)
    const {masterServiceAgreement, dataProcessingAgreement, privacyPolicy} =
        useTermsDocuments(country)
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp})`)

    useEffect(() => {
        trackPricingPlansModalActioned({
            action: 'viewed',
            step: trackingStep,
            origin: pageOrigin,
            section: pageSection,
            plan: targetPlan.planType,
        })
    }, [])

    if (isPaymentSummaryError) {
        return <ErrorCard />
    }
    const locale = user?.language!

    const isTargetYearly = targetBillingType === 'YEAR'

    const targetPayment = data && (isTargetYearly ? data.YEAR : data.MONTH)
    const yearlyDiscount =
        (data?.YEAR.totalIncludingTax.value ?? 0) / 12 - (data?.MONTH.totalIncludingTax.value ?? 0)

    const targetPaymentValue = targetPayment?.totalIncludingTax.value ?? 0
    const targetPaymentPerMonth = isTargetYearly ? targetPaymentValue / 12 : targetPaymentValue
    const targetPaymentCurrency = targetPayment?.totalIncludingTax.currency

    const formattedTargetSubtotal = formatMinorNumberToCurrency({
        value: targetPaymentPerMonth,
        locale,
        options: {...paymentFormatOptions, currency: targetPaymentCurrency},
    })

    // Current billing is calculated as monthly
    const currentBilling =
        currentBillingType === 'YEAR'
            ? (currentPlanEstimatedTotal?.value ?? 0) / 12
            : currentPlanEstimatedTotal?.value ?? 0

    // Price difference is calculated as monthly
    const priceDifference = formatMinorNumberToCurrency({
        value: targetPaymentPerMonth - currentBilling,
        locale,
        options: {...paymentFormatOptions, currency: targetPaymentCurrency, signDisplay: 'always'},
    })

    // Due today is calculated as monthly or yearly depending on the target billing type
    const dueToday = formatMinorNumberToCurrency({
        value: targetPaymentValue,
        locale,
        options: {...paymentFormatOptions, currency: targetPaymentCurrency},
    })

    const handleSubmit = () => {
        updateCompanySubscription({
            billingPeriodType: targetBillingType,
            ratePlanType: targetPlan.planType,
            subscriptionUpdateSource: planUpgradeSource,
        })
    }

    const agreementText = targetPlan.isUpgrade
        ? t`By clicking Upgrade you agree to Pleo's`
        : t`By clicking Downgrade you agree to Pleo's`

    return (
        <Card padding={32} css={{maxWidth: isMobile ? 'auto' : '360px'}}>
            <Stack space={16} css={{width: '100%', margin: '0 auto'}} stretch>
                <Skeleton loading={isFetching}>
                    <Text variant="xlarge-accent" weight="medium">
                        <Trans>Price breakdown</Trans>
                    </Text>
                </Skeleton>
                <Stack space={12} stretch>
                    {targetPayment?.platformIncludingTax !== undefined && (
                        <Skeleton loading={isFetching}>
                            <Inline space={16} justifyContent="space-between">
                                <Text variant="medium-default">
                                    <Trans>{PlanTypeName[targetPlan.planType]} plan</Trans>
                                </Text>
                                <Text variant="medium-default" data-testid="payment-plan">
                                    <Trans>
                                        {formatMinorNumberToCurrency({
                                            value: isTargetYearly
                                                ? targetPayment?.platformIncludingTax.value / 12
                                                : targetPayment?.platformIncludingTax.value,
                                            locale,
                                            options: {
                                                ...paymentFormatOptions,
                                                currency:
                                                    targetPayment?.platformIncludingTax.currency,
                                            },
                                        })}
                                        /month
                                    </Trans>
                                </Text>
                            </Inline>
                        </Skeleton>
                    )}
                    {targetPayment?.usersIncludingTax !== undefined && (
                        <Skeleton loading={isFetching}>
                            <Inline space={16} justifyContent="space-between">
                                <Text variant="medium-default">
                                    <Trans>Additional users</Trans>
                                </Text>
                                <Text
                                    variant="medium-default"
                                    data-testid="payment-additional-users"
                                >
                                    <Trans>
                                        {formatMinorNumberToCurrency({
                                            value: isTargetYearly
                                                ? targetPayment?.usersIncludingTax.value / 12
                                                : targetPayment?.usersIncludingTax.value,
                                            locale,
                                            options: {
                                                ...paymentFormatOptions,
                                                currency:
                                                    targetPayment?.platformIncludingTax?.currency,
                                            },
                                        })}
                                        /month
                                    </Trans>
                                </Text>
                            </Inline>
                        </Skeleton>
                    )}
                    {isTargetYearly && targetPlan.isUpgrade && (
                        <Skeleton loading={isFetching}>
                            <Inline space={16} justifyContent="space-between">
                                <Text variant="medium-default">
                                    <Trans>Anual billing discount</Trans>
                                </Text>
                                <Text variant="medium-default" data-testid="payment-anual-discount">
                                    <Trans>
                                        {formatMinorNumberToCurrency({
                                            value: yearlyDiscount,
                                            locale,
                                            options: {
                                                ...paymentFormatOptions,
                                                currency:
                                                    targetPayment?.platformIncludingTax?.currency,
                                                signDisplay: 'always',
                                            },
                                        })}
                                        /month
                                    </Trans>
                                </Text>
                            </Inline>
                        </Skeleton>
                    )}
                </Stack>
                <Card.Divider />
                <Skeleton loading={isFetching}>
                    <div>
                        <Inline space={16} justifyContent="space-between">
                            <Text variant="large-accent" weight="medium">
                                <Trans>New price</Trans>
                            </Text>
                            <Text
                                variant="large-accent"
                                weight="medium"
                                data-testid="payment-new-price"
                            >
                                <Trans>{formattedTargetSubtotal}/month</Trans>
                            </Text>
                        </Inline>
                        <Inline justifyContent="space-between">
                            <Text variant="small-subtle" color="colorContentStaticQuiet">
                                <Trans>Compared to {PlanTypeName[currentPlan]}</Trans>
                            </Text>
                            <Text
                                variant="small-subtle"
                                color="colorContentStaticQuiet"
                                data-testid="payment-difference"
                            >
                                <Trans>{priceDifference}/month</Trans>
                            </Text>
                        </Inline>
                    </div>
                </Skeleton>
                <Card.Divider />
                <Skeleton loading={isFetching}>
                    <div>
                        <Inline space={16} justifyContent="space-between">
                            <Text variant="large-accent" weight="medium">
                                <Trans>To pay now</Trans>
                            </Text>
                            <Text
                                variant="large-accent"
                                weight="medium"
                                data-testid="payment-pay-now"
                            >
                                {dueToday}
                            </Text>
                        </Inline>
                        <Text variant="small-subtle" color="colorContentStaticQuiet">
                            <Trans>Any unused time will be automatically refunded.</Trans>
                        </Text>
                    </div>
                </Skeleton>
                <Skeleton loading={isFetching}>
                    <Button
                        variant="primary"
                        destructive={!targetPlan.isUpgrade ? true : false}
                        fullWidth
                        onClick={handleSubmit}
                        data-testid="payment-confirm-button"
                        loading={isUpdateCompanySubscriptionLoading}
                    >
                        {targetPlan.isUpgrade
                            ? t`Upgrade to ${PlanTypeName[targetPlan.planType]}`
                            : t`Downgrade to ${PlanTypeName[targetPlan.planType]}`}
                        &nbsp;
                    </Button>
                </Skeleton>
                <Text
                    variant="small-subtle"
                    color="colorContentStaticQuiet"
                    aria-label={t`${agreementText} Master Service Agreement, Data Processing Agreement and Privacy Policy`}
                >
                    <Trans>
                        {agreementText}{' '}
                        <Link
                            href={masterServiceAgreement}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={{fontSize: tokens.fontSmall}}
                            onClick={() => {
                                trackPricingPlansModalActioned({
                                    action: 'master_service_agreement_link',
                                    step: trackingStep,
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: targetPlan.planType,
                                })
                            }}
                        >
                            Master Service Agreement
                        </Link>
                        ,{' '}
                        <Link
                            href={dataProcessingAgreement}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={{fontSize: tokens.fontSmall}}
                            onClick={() => {
                                trackPricingPlansModalActioned({
                                    action: 'data_processing_agreement_link',
                                    step: trackingStep,
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: targetPlan.planType,
                                })
                            }}
                        >
                            Data Processing Agreement
                        </Link>{' '}
                        and{' '}
                        <Link
                            href={privacyPolicy}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={{fontSize: tokens.fontSmall}}
                            onClick={() => {
                                trackPricingPlansModalActioned({
                                    action: 'privacy_policy_link',
                                    step: trackingStep,
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: targetPlan.planType,
                                })
                            }}
                        >
                            Privacy Policy
                        </Link>
                        .
                    </Trans>
                </Text>
            </Stack>
        </Card>
    )
}

const ErrorCard = () => {
    return (
        <Card padding={32} css={{maxWidth: '360px'}}>
            <Stack space={16}>
                <Text variant="xlarge-accent" weight="medium">
                    <Trans>Price breakdown</Trans>
                </Text>

                <Card.Divider />
                <Text variant="large-accent" color="colorContentStaticQuiet">
                    <Trans>
                        Sorry, something went wrong when loading the breakdown. Please go back and
                        try again, or reach out to us for help.
                    </Trans>
                </Text>
                <ContactSupport
                    chatLabel={t`Chat with us`}
                    css={{whiteSpace: 'nowrap'}}
                    buttonVariant="secondary"
                />
            </Stack>
        </Card>
    )
}
