import {t, Trans} from '@lingui/macro'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useGate} from 'statsig-react'
import styled from 'styled-components'

import {Box, ProgressBar, Stack, Text, tokens} from '@pleo-io/telescope'

import {RecurringSpendAnnouncement} from './announcement-modal'

interface ProgressCardProps {
    isTablet: boolean
}

export const ProgressCard = ({isTablet}: ProgressCardProps) => {
    const {value: showProgressCard, isLoading} = useGate('spend_activation_setup_flow')
    const navigate = useNavigate()
    const [showModal, setShowModal] = React.useState(false)

    if (!showProgressCard || isLoading) {
        return null
    }

    return (
        <Wrapper onClick={() => navigate('/recurring-vendors/setup-guide')}>
            <StyledBox
                isTablet={isTablet}
                borderRadius={8}
                paddingTop={12}
                paddingX={16}
                paddingBottom={16}
                onClick={() => setShowModal(true)}
            >
                <Stack space={8}>
                    <Text>
                        <Trans>Set up recurring spend</Trans>
                    </Text>

                    <ProgressBar
                        aria-label={t`progress bar showing percentage of recurring spend setup`}
                        variant="tall"
                        percent={50}
                        fillColor={'green500'}
                    />
                </Stack>
            </StyledBox>

            <RecurringSpendAnnouncement isOpen={showModal} setShowModal={setShowModal} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    cursor: pointer;
    padding: ${tokens.spacing12} ${tokens.spacing16} ${tokens.spacing16};
    justify-self: stretch;
`

interface StyledBoxProps {
    isTablet: boolean
}

const StyledBox = styled(Box)<StyledBoxProps>`
    background-color: ${({isTablet}) =>
        isTablet ? tokens.colorBackgroundInteractiveLoud : tokens.colorBackgroundInteractive};
`
